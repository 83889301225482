

















































































































































import { Vue, Component, Watch } from 'vue-property-decorator';
import { getScreenSizeVariant } from '@/vue-app/utils/screen';
import ContractSavingsProfilingDepositsAndWithdrawalsViewModel
  from '@/vue-app/view-models/components/contract-savings/contract-savings-profiling-deposits-and-withdrawals-view-model';
import CustomAutocomplete from '@/vue-app/components/CustomAutocomplete.vue';

@Component({
  name: 'ContractSavingsProfilingDepositsAndWithdrawals',
  components: {
    CustomAutocomplete,
    ContractSavingsActions: () => import('@/vue-app/components/contract-savings/ContractSavingsActions.vue'),
  },
})
export default class ContractSavingsProfilingDepositsAndWithdrawals extends Vue {
  profiling_deposit_and_withdrawals_view_model = Vue.observable(
    new ContractSavingsProfilingDepositsAndWithdrawalsViewModel(),
  );

  @Watch('profiling_deposit_and_withdrawals_view_model.is_loading')
  onLoadChange(is_loading: boolean) {
    this.$emit('loadingInfo', is_loading);
  }

  prevStep() {
    this.$emit('prevStep');
  }

  async nextStep() {
    const saved_step = await this.profiling_deposit_and_withdrawals_view_model.saveStep();
    if (saved_step) {
      this.$emit('nextStep');
    }
  }

  async created() {
    this.profiling_deposit_and_withdrawals_view_model.small_screen = getScreenSizeVariant() === 'small';
    await this.profiling_deposit_and_withdrawals_view_model.initialize();
  }
}

